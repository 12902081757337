import { useState } from 'react'
import emailjs from '@emailjs/browser'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import bookCover from './assets/book-cover.jpg'
import logo from './assets/logo.png'
import './App.css'

const spreadsheetsLink = 'https://docs.google.com/spreadsheets/d/1ris73XihOlGizKlOoglTPsAr0PLbvEs5p2VCYR3c_bs/edit?usp=sharing'
const videoTutorialLink = 'https://youtu.be/liiM6AfdpJU'
const skillsWorksheetLink = 'https://drive.google.com/file/d/1sdxCnM9B1sA_kzyJ2sWqxzFvC9aHwARy/view?usp=sharing'
const partnerAccountabilityGuideLink = 'https://drive.google.com/file/d/1TE1XtRrDrr5WSJ-OamS3lBJ7ZKlmPZh7/view?usp=sharing'
const amazonBookLink = 'https://www.amazon.com/dp/B0DKKS6G12'

const isValidEmail = email => {
  //eslint-disable-next-line
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ 
  return regex.test(String(email).toLowerCase())
}

const FreeResourcesIntro = (props) => {
  const { setEmailSent } = props
  emailjs.init('cOa9hRG5GSC9LfcfO')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')

  const submit = () => {
    if (name && email) {
      const serviceId = 'service_7slh93e'
      const templateId = 'template_wcg9cd4'
      const templateParams = {
        name,
        email
      }

      emailjs.send(serviceId, templateId, templateParams)
        .then(response => {
          setName('')
          setEmail('')
          setEmailSent(true)
        })
        .catch(error => console.log(error))
    }
  }

  const buttonIsDisabled = name === '' || email === '' || !isValidEmail(email)

  return (
    <Col>
      <h1>Free Resources</h1>
      <p>
        <h2>Thank you for buying the book!</h2>

        These are the free resources you will receive for subscribing:

        <ul>
          <li>Spreadsheet templates where we did the set up for you.</li>

          <li>Spreadsheet fundamentals training through video and PDF to help you master the skills you need to do all the Joyful Money Mastery steps.</li>

          <li>An expanded accountability partner guide so they know how to keep you on track.</li>
        </ul>

        <h2>Subscribe</h2>
        <p>Sign up with your email address to receive news and updates, and your free resources will immediately be available.</p>
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" placeholder="Enter name" onChange={e => setName(e.target.value)} />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" placeholder="Enter email" onChange={e => setEmail(e.target.value)} />
            <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text>
          </Form.Group>
          
          <Button variant="outline-dark" disabled={buttonIsDisabled} onClick={() => submit()}>
            Submit
          </Button>
        </Form>
      </p>
    </Col>
  )
}

const EarnedResources = () => {
  return (
    <Col>
      <h1>Free Resources</h1>
      <p>
        <h2>YOUR FREE RESOURCES HAVE ARRIVED</h2>
        
        <ul>
          <li>
            <a href={spreadsheetsLink} target="_blank" rel="noopener noreferrer">Spreadsheet templates</a> where we did the set up for you.</li>

          <li>Spreadsheet fundamentals training to help you master the skills you need to do all the Joyful Money Mastery steps.
              <ul>
                <li><a href={videoTutorialLink} target="_blank" rel="noopener noreferrer">Video tutorial</a></li>
                <li><a href={skillsWorksheetLink} target="_blank" rel="noopener noreferrer">Skills worksheet</a></li>
              </ul>
          </li>

          <li><a href={partnerAccountabilityGuideLink} target="_blank" rel="noopener noreferrer">An expanded accountability partner guide</a> so they know how to keep you on track.</li>
        </ul>

        <p>Excited about your journey!</p>
      </p>
    </Col>
  )
}

const FreeResources = () => {
  const [emailSent, setEmailSent] = useState(false)

  return (
      <Container fluid>
        <Row className="logo-row">
            <Col>
              <a href="/"><img src={logo} alt="Joyful Money Mastery logo" className="logo-img" /></a>
            </Col>
          </Row>
        <Row className="main-content">
          {!emailSent ? <FreeResourcesIntro emailSent={emailSent} setEmailSent={setEmailSent} /> : <EarnedResources />}
          <Col>
          <a href={amazonBookLink} target="_blank" rel="noopener noreferrer">
              <img src={bookCover} alt="book cover" className="book-img" />
            </a>
          </Col>
        </Row>
    </Container>
  )
}

export default FreeResources