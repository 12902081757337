import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import logo from './assets/logo.png'

const CourseTerms = () => {

  return (
    <Container fluid>
    <Row className="logo-row">
        <Col>
          <a href="/"><img src={logo} alt="Joyful Money Mastery logo" className="logo-img" /></a>
        </Col>
      </Row>
    <Row className="main-content">
      <h2>Terms and Conditions</h2>
      <h5>Effective Date: February 25, 2025</h5>
      <p><h6>1. Introduction</h6> Welcome to Joyful Money Mastery! By enrolling in this online course, you agree to abide by these Terms and Conditions. Please read them carefully before proceeding.</p>
      <p><h6>2. No Financial Advice</h6> Joyful Money Mastery provides educational content on managing personal finances. However, it does not offer financial, investment, legal, or tax advice. Rachel Bird, the creator of the course, is not a financial advisor, and any information shared is for general educational purposes only. You are encouraged to seek professional advice for your specific financial situation.</p>
      <p><h6>3. No Guarantees</h6> While Joyful Money Mastery aims to provide valuable insights into personal finance management, no guarantees are made regarding financial outcomes or results. Your financial success depends on various factors beyond the scope of this course.</p>
      <p><h6>4. Course Access and Availability</h6> Upon purchase, you will receive access to the course materials for the life of the course. However, Rachel Bird reserves the right to modify, update, or discontinue the course at her discretion. In the event of course discontinuation, reasonable notice will be provided to enrolled students.</p>
      <p><h6>5. Live Sessions and Email Support</h6> Live sessions, including Joy Sessions and office hours, as well as email support, will be available for the life of the course. These sessions will be scheduled at the discretion of Rachel Bird, and students will be notified via email when they are set. Availability of live interactions is not guaranteed at specific times or frequencies.</p>
      <p><h6>6. Intellectual Property</h6> All course content, including videos, worksheets, and other materials, are the intellectual property of Rachel Bird. Enrollees are granted a non-transferable, non-exclusive license to access and use these materials for personal educational purposes only. Redistribution, reproduction, or resale of course content is strictly prohibited.</p>
      <p><h6>7. Refund Policy</h6> Due to the nature of digital products, all sales are final. Once you have been granted access to the course materials, no refunds or exchanges will be provided. Please ensure that you review all course details before making a purchase.</p>
      <p><h6>8. Limitation of Liability</h6> Rachel Bird and Joyful Money Mastery shall not be held liable for any direct, indirect, incidental, or consequential damages arising from your participation in the course or reliance on any information provided. Enrollment is at your own risk.</p>
      <p><h6>9. Modifications to Terms</h6> Rachel Bird reserves the right to update these Terms and Conditions at any time. Enrolled students will be notified of significant changes.</p>
      <p><h6>10. Contact Information</h6> For any questions regarding these Terms and Conditions, please contact Rachel at joyfulmoneymastery@gmail.com.</p>
      <p>By enrolling in Joyful Money Mastery, you acknowledge that you have read, understood, and agreed to these Terms and Conditions.</p>
    </Row>
  </Container>
  )
}

export default CourseTerms