import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';

const Testimonials = () => {
  return (
    <Card style={{ margin: '0 5vw', background: '#faf5ed' }}>
      <Card.Body>
        <Card.Title><h2 style={{ color: "#012946", marginBottom: '20px'}}>What people are saying</h2></Card.Title>
        <CardGroup>
          <Card>
            <Card.Body>
              <Card.Text>
                <small>I thought I had pretty solid fundamentals in personal financials. Turns out there were a *few* shaky areas. Rachel’s <i>Joyful Money Mastery</i> book gently pointed out the weak spots and provided real-life exercises for improving them! I am so excited to use the new skills I learned to achieve some short- and long-term goals I have!</small>
              </Card.Text>
              <small><em>Donia Robinson (book)</em></small>
            </Card.Body>
          </Card>

          <Card>
            <Card.Body>
              <Card.Text>
                <small>I’m doing my accounts snapshot and inputting my monthly transaction info into my spreadsheet and guess what! I discovered $962 waiting for me in my PayPal account! Finally giving my finances attention through <i>Joyful Money Mastery</i> (rather than being too afraid to even look at them) is like lifting up the couch cushions and finding 10 hundred dollar bills! 😂</small>
              </Card.Text>
              <small><em>Danielle YS (course)</em></small>
            </Card.Body>
          </Card>

          <Card>
            <Card.Body>
              <Card.Text>
                <small><i>Joyful Money Mastery</i> is an excellent book for people who are either working to achieve financial goals or are thinking that their life isn’t fun any more as they become obsessed with making money. It is also provides a comprehensive overview and guide to forward planning and every day decision making.</small>
              </Card.Text>
              <small><em>John Baillie (book)</em></small>
            </Card.Body>
          </Card>
        </CardGroup>
      </Card.Body>
    </Card>
  )
}

export default Testimonials
