  import Container from 'react-bootstrap/Container'
  import Row from 'react-bootstrap/Row'
  import Col from 'react-bootstrap/Col'
  import Button from 'react-bootstrap/Button'
  import Card from 'react-bootstrap/Card'
  import Testimonials from './Testimonials'
  import bookCover from './assets/book-cover.jpg'
  import logo from './assets/logo.png'
  import './App.css'

  function App() {
    return (
      <div className="App">
        <Container fluid>
          <Row className="logo-row">
            <Col>
              <img src={logo} alt="Joyful Money Mastery logo" className="logo-img" />
            </Col>
          </Row>
          <Row className="main-content">
            <Col>
              <h1 className="dark-blue">Learn how to master your personal finances.</h1>
              <ul>
                <li>Master your money mindset.</li>
                <li>Set up your money management system.</li>
                <li>Level up your financial literacy.</li>
              </ul>
              <div>
                <a href="https://www.amazon.com/dp/B0DKKS6G12" target="_blank" rel="noopener noreferrer">
                 <Button variant="outline-light" className="buy-book-col" size="lg" style={{marginRight: '25px'}}>Get the book</Button>
                </a>
                {/* <a href="https://joyfulmoneymastery.kit.com/products/joyful-money-mastery-course" target="_blank" rel="noopener noreferrer">
                  <Button variant="light" className="buy-book-col" size="lg">Start the course</Button>
                </a> */}
              </div>
            </Col>
            <Col>
              <a href="https://www.amazon.com/dp/B0DKKS6G12" target="_blank" rel="noopener noreferrer">
                <img src={bookCover} alt="book cover" className="book-img" />
              </a>
            </Col>
          </Row>
        </Container>
        <Testimonials />
        <Card style={{ margin: '2vh 5vw', background: '#faf5ed' }}>
          <Card.Body>
            <Card.Text><a href="https://fungamesforlearning.netlify.app/" target="_blank" rel="noopener noreferrer" className="dark-blue">Play the vocabulary game</a> made just for Joyful Money Mastery!</Card.Text>
          </Card.Body>
        </Card>
        <Row className="main-content dark-blue">
          {/* <Col><a href="/course-terms" className="dark-blue">Course Terms and Conditions</a></Col> */}
          <Col>joyfulmoneymastery@gmail.com</Col>
        </Row>
      </div>
    )
  }

  export default App
